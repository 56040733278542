import { graphql, PageProps } from 'gatsby'
import React, { useMemo } from 'react'
import { PostPageContext } from './PostPage.context'
import PostContent from '../components/PostContent'
import PostContainer from '../components/PostContainer'
import BlogMorePosts from '../components/BlogMorePosts'
import { BlogPostQuery } from '../_generated/codegen/graphqlTypes'
import { Unpacked } from '../utils/Unpacked'
import notEmpty from '../utils/notEmpty'

const useItem = (data: BlogPostQuery): IBlogPost | undefined => {
  return useMemo(() => {
    const items = data.strapi.post?.filter(notEmpty) ?? []
    if (items.length > 0) {
      return items[0]
    }
    return undefined
  }, [data])
}

const useTops = (data: BlogPostQuery): IBlogPostTop[] => {
  return useMemo(() => {
    return data.strapi.top?.filter(notEmpty) ?? []
  }, [data])
}

const PostPage: React.FC<PageProps<BlogPostQuery, PostPageContext>> = ({
  data,
}) => {
  const item = useItem(data)
  const tops = useTops(data)

  if (!item) {
    return <></>
  }

  return (
    <PostContainer>
      <PostContent item={item} />
      <BlogMorePosts
        title="Autres articles susceptibles de vous intéresser :"
        items={tops}
      />
    </PostContainer>
  )
}

export type IBlogPost = NonNullable<
  Unpacked<NonNullable<BlogPostQuery['strapi']['post']>>
>

type IBlogPostTop = NonNullable<
  Unpacked<NonNullable<BlogPostQuery['strapi']['top']>>
>

export const query = graphql`
  fragment uploadFileFields on Strapi_UploadFile {
    url
    name
  }

  fragment topFields on Strapi_BlogPost {
    id
    slug
    title
    tags {
      id
      name
      slug
    }
    category {
      id
      name
      slug
    }
  }

  query BlogPost($slug: String!, $category: String!) {
    strapi {
      post: blogPosts(where: { slug: $slug, category: { slug: $category } }) {
        ...topFields
        cover {
          ...uploadFileFields
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, width: 640)
            }
          }
        }
        subtitle
        description
        content
        video
        published_at
        author {
          id
          extra {
            id
            lastname
            firstname
            avatar {
              ...uploadFileFields
              imageFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100, width: 50)
                }
              }
            }
          }
        }
      }
      top: blogPosts(
        start: 0
        limit: 10
        sort: "published_at:desc"
        where: { top: true }
      ) {
        ...topFields
        cover {
          ...uploadFileFields
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, width: 180)
            }
          }
        }
      }
    }
  }
`

export default PostPage
